import { ApexOptions } from "apexcharts";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./Offcanvas.css";

const units: any = {
    pr: "[mm]",
    te: "[degrees Celsius]",
    ra: "W/m2",
    rh: "[-]",
    ws: "[m/s]",
    wl: "[meters]",
}

type props = {
    page: string
    title: string
    series: ApexAxisChartSeries
    seriesData: any
    setSeries: Dispatch<SetStateAction<ApexAxisChartSeries>>
    code: string
}

export const OffCanvas = ({ page, title, series, seriesData, setSeries, code }: props) => {
    const [unit, setUnit] = useState<string>('[mm]')

    useEffect(() => {
        if (page === 'meteorological') {
            const sel = document.getElementById('select') as HTMLSelectElement
            sel!.value = 'pr'
            setUnit(units['pr'])
        }

        if (page === "hydrological") {
            setUnit(units['wl'])
        }

        if (page === "rainfall forecast") {
            setUnit(units['pr'])
        }
    }, [code, page])

    const options: ApexOptions = {
        title: {
            text: title,
            align: 'center',
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                color: '#263238'
            },
        },
        fill: {
            opacity: 0.5
        },
        stroke: {
            width: 1
        },
        xaxis: {
            type: "datetime",
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                },
            }
        },
        yaxis: {
            decimalsInFloat: 2,
            title: {
                text: unit
            }
        }
    }

    const chooseVariable = (varr: string) => {
        setUnit(units[varr])
        setSeries(seriesData[code][varr])
    }

    return (
        <div className="p-0 offcanvas offcanvas-bottom"
            data-bs-scroll="true"
            data-bs-backdrop="false"
            style={{ marginLeft: "var(--ct-leftbar-width)", height: "35vh", width: "calc(100vw - var(--ct-leftbar-width))" }}
            tabIndex={-1} id="offcanvasBottom"
            aria-labelledby="offcanvasBottomLabel">
            {page === 'meteorological' ? (
                <div className="offcanvas-header p-1 pe-3">
                    <select id="select" className="form-select m-2 mb-0 mt-0" style={{ width: "25%" }} onChange={(e: ChangeEvent<HTMLSelectElement>) => chooseVariable(e.currentTarget.value)}>
                        <option value="pr">Precipitation</option>
                        <option value="te">Temperature</option>
                        <option value="rh">Relative Humidity</option>
                        <option value="ra">Solar Radiation</option>
                        <option value="ws">Wind Speed</option>
                    </select>
                    <button id="close" type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
            ) : (
                <div className="offcanvas-header p-1 pe-3 justify-content-end">
                    <button id="close" type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
            )}
            <div className="offcanvas-body p-0">
                <div className="col-12" style={{ height: "90%" }}>
                    <Chart
                        options={options}
                        series={series}
                        width="98%"
                        height="100%"
                        className="apex-charts m-0"
                    />
                </div>
            </div>
        </div>
    )
}