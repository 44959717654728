import { Dispatch, SetStateAction } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Sidebar.css";


type getdateProp = {
    endDate?: Date | null;
    setEndDate: Dispatch<SetStateAction<Date | null>>
    setLoading: Dispatch<SetStateAction<boolean>>
    disable?: boolean
}

export const Sidebar = ({ endDate, setEndDate, setLoading, disable = false }: getdateProp) => {
    return (
        <div className="leftside-menu">
            <a href="/" className="logo logo-light p-0">
                <span className="logo-lg">
                    <img src="/assets/images/partners.png" style={{ height: "4rem" }} alt="logo" />
                </span>
                <span className="logo-sm">
                    <img src="/assets/images/partners.png" alt="small logo" />
                </span>
            </a>
            <a href="/" className="logo logo-dark">
                <span className="logo-lg">
                    <img src="/assets/images/partners.png" style={{ height: "4rem" }} alt="logo" />
                </span>
                <span className="logo-sm">
                    <img src="/assets/images/partners.png" alt="small logo" />
                </span>
            </a>
            <div className="button-sm-hover" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
                <i className="ri-checkbox-blank-circle-line align-middle"></i>
            </div>
            <div className="button-close-fullsidebar">
                <i className="ri-close-fill align-middle"></i>
            </div>
            <div className="h-100 pt-3" id="leftside-menu-container" data-simplebar>
                <ul className="side-nav">
                    <li className="side-nav-item">
                        <a data-bs-toggle="collapse" href="#measurements" aria-expanded="false"
                            aria-controls="measurements" className="side-nav-link">
                            <i className="uil-layer-group"></i>
                            <span> Measurements </span>
                            <span className="menu-arrow"></span>
                        </a>
                        <div className="collapse" id="measurements">
                            <ul className="side-nav-second-level">
                                <li>
                                    <a href="/meteorological"> Meteorological </a>
                                </li>
                                <li>
                                    <a href="/hydrological"> Hydrological </a>
                                </li>
                                <li>
                                    <a href="/water-discharge"> Water Discharge </a>
                                </li>
                                <li>
                                    <a href="#cml" className="disabled btn border-0 ps-0" role="button"> Commercial Microwave Links </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <hr style={{ opacity: "0.3" }} />
                    <li className="side-nav-item">
                        <a data-bs-toggle="collapse" href="#sidebarMultiLevel" aria-expanded="false"
                            aria-controls="sidebarMultiLevel" className="side-nav-link">
                            <i className="uil-layer-group"></i>
                            <span> Forecasts </span>
                            <span className="menu-arrow"></span>
                        </a>
                        <div className="collapse" id="sidebarMultiLevel">
                            <ul className="side-nav-second-level">
                                <li className="side-nav-item">
                                    <a href="/rainfall-forecast">
                                        <i className="uil-cloud-sun-tear"></i>
                                        <span> Rainfall </span>
                                    </a>
                                </li>
                                <li className="side-nav-item">
                                    <a data-bs-toggle="collapse" href="#sidebarThirdLevel" aria-expanded="false"
                                        aria-controls="sidebarThirdLevel">
                                        <i className="uil-water"></i>
                                        <span> Flood </span>
                                        <span className="menu-arrow"></span>
                                    </a>
                                    <div className="collapse" id="sidebarThirdLevel">
                                        <ul className="side-nav-third-level">
                                            <li>
                                                <a href="#water-levels" className="disabled btn border-0 ps-0" role="button">Water Levels</a>
                                            </li>
                                            <li>
                                                <a href="#flooded-areas" className="disabled btn border-0 ps-0" role="button">Flooded Areas</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <hr style={{ opacity: "0.3" }} />
                    <li className="side-nav-item">
                        <div className="side-nav-link py-1">
                            <i className="ri-calendar-2-fill"></i>
                            <span>
                                <DatePicker
                                    disabled={disable}
                                    selected={endDate}
                                    onChange={(date: Date | null) => { setLoading(true); setEndDate(date) }}
                                    maxDate={new Date()}
                                    showMonthDropdown
                                    showYearDropdown
                                    id='datepicker'
                                />
                            </span>
                        </div>
                    </li>
                    <li className="side-nav-item">
                        <div className="side-nav-link">
                            <i className="uil-comment-info"></i>
                            <span>
                                <button type="button" className="btn btn-dark p-0 ps-1" style={{ color: "var(--ct-menu-item-color)" }} data-bs-toggle="modal" data-bs-target="#fill-dark-modal"> Help? </button>
                            </span>
                        </div>
                    </li>
                </ul>

                <div className="clearfix"></div>
            </div>
        </div>
    )
}
