import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<GoogleOAuthProvider clientId={`${process.env.REACT_APP_CLIENT_ID}`}>
				<App />
			</GoogleOAuthProvider>
		</BrowserRouter>
	</React.StrictMode >
);
