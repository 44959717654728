import { GeoJsonObject } from "geojson";
import { Icon } from "leaflet";
import { Dispatch, SetStateAction, useEffect, useState, } from "react";
import { GeoJSON, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import basinData from '../../utils/AboaboBasin/Aboabo_Basin_GSMA.json';


interface ORCMapContainerInterface {
    markers: Array<{ id: number, name: string, coordinates: number[] }> | null
    sites: any
    setSite: Dispatch<SetStateAction<{}>>
    loading: boolean
}

export const ORCMapContainer = ({ markers, setSite, sites, loading }: ORCMapContainerInterface) => {
    const [geojsonFile, setGeoFile] = useState<GeoJsonObject | null>(null)
    useEffect(() => {
        setGeoFile(basinData as GeoJsonObject);
    }, [])
    return (
        <MapContainer center={[6.709327322997796, -1.559240192997633]} zoom={12}>
            <button id="rightTrigger" className="btn btn-primary mt-2 mt-md-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style={{ display: "none" }}></button>
            <TileLayer
                attribution='Google'
                url='https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
            />
            {
                loading ? (
                    <div id="preloader" style={{ opacity: "0.9" }} >
                        <div id="status" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <span className="h3" > Loading </span>
                            <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>) : markers ? markers.map((marker, ind) => {
                        const iconUrl = require(`../../utils/MarkerIcon/location.png`);
                        const customIcon = new Icon({
                            iconUrl,
                        })
                        return (
                            <Marker key={marker.id} position={[marker.coordinates[0], marker.coordinates[1]]} icon={customIcon} eventHandlers={{
                                click: () => {
                                    setSite(sites[marker.id])
                                    const btn = document.getElementById('rightTrigger') as HTMLButtonElement;
                                    const offcanvas = document.getElementById('offcanvasRight');
                                    if (!(offcanvas?.classList.contains('show'))) {
                                        btn.click()
                                    }
                                }
                            }}><Popup>
                                    {marker.name}
                                </Popup></Marker>
                        )
                    }) : <></>}
            {geojsonFile && <GeoJSON data={geojsonFile} />}
        </MapContainer >
    )
}