import axios from "axios";
import { useEffect, useState } from "react";
import { Legend } from "../../Components/Legend/Legend";
import { MapContainerComponent } from "../../Components/MapContainer/MapContainer";
import { Navbar } from "../../Components/Navbar/Navbar";
import { OffCanvas } from "../../Components/Offcanvas/Offcanvas";
import { Sidebar } from "../../Components/Sidebar/Sidebar";
import "../global.css";


export const Hydrological = () => {
    const [endDate, setEndDate] = useState<Date | null>(new Date())
    const [loading, setLoading] = useState(false)
    const [stationData, setStationData] = useState<any>({})
    const [markers, setMarkers] = useState<any>({})
    const [title, setTitle] = useState('')
    const [series, setSeries] = useState<any[]>([])
    const [code, setCode] = useState("")

    useEffect(() => {
        setInterval(() => {
            window.location.reload()
        }, 1000 * 60 * 60 * 1);
    }, [])

    useEffect(() => {
        const btn = document.getElementById('trigger') as HTMLButtonElement;
        const offcanvas = document.getElementById('offcanvasBottom');
        if (offcanvas?.classList.contains('show')) {
            btn.click()
        }
        setLoading(true)

        const end = endDate!.toISOString().split('.')[0] + 'Z'
        axios.get(`/backend-api/fews/hydrological-data?end=${end}`)
            .then(res => {
                const data = res.data
                if (data.status === "success") {
                    setStationData(data.data)
                    setMarkers(data.markers)
                } else {
                    throw new Error("Something went wrong");
                }
                setLoading(false)
            })
            .catch((err) => { alert('Oops, something went wrong, try again later.'); setLoading(false) })

    }, [endDate])

    const displayCanvas = (page: string, title: string, code: string) => {
        setTitle(title)
        setSeries(stationData[code])
        const btn = document.getElementById('trigger') as HTMLButtonElement;
        const offcanvas = document.getElementById('offcanvasBottom');
        if (!(offcanvas?.classList.contains('show'))) {
            btn.click()
        }
    }

    return <>
        <Navbar />
        <Sidebar endDate={endDate} setEndDate={setEndDate} setLoading={setLoading} />
        <Legend />
        <div id="content" className="content-page">
            <div className="content" style={{ minHeight: 0 }}>
                <div className="container-fluid">
                    <div className="row">
                        <MapContainerComponent loading={loading} markers={markers} displayCanvas={displayCanvas} setCode={setCode} page="hydrological" />
                        <OffCanvas title={title} page='hydrological' series={series} seriesData={stationData} setSeries={setSeries} code={code} />
                    </div>
                </div>
            </div>
        </div>
    </>
}