import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

type ScatterChartProps = {
    seriesData: ApexAxisChartSeries
}

export const ScatterPlot = ({ seriesData }: ScatterChartProps) => {

    const series: ApexAxisChartSeries = seriesData
    const options: ApexOptions = {
        legend: {
            show: true,
        },
        xaxis: {
            tickAmount: 10,
            labels: {
                formatter: function (val) {
                    return parseFloat(val).toFixed(2)
                }
            },
            title: {
                text: "q_50",
                style: {
                    fontSize: "1.2rem"
                }
            }
        },
        yaxis: {
            decimalsInFloat: 2,
            tickAmount: 7,
            title: {
                text: "h",
                style: {
                    fontSize: "1.2rem"
                }
            }
        }
    }
    return <Chart options={options} series={series} type="scatter" width="100%" height="100%" className="apex-chartsuy" />
}