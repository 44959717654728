import { GeoJsonObject } from "geojson";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GeoJSON, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import basinData from '../../utils/AboaboBasin/Aboabo_Basin_GSMA.json';

type MapContainerComponentProp = {
    loading: boolean
    markers: any
    displayCanvas: (page: string, title: string, code: string) => void
    setCode?: Dispatch<SetStateAction<string>>
    page: string
}

export const MapContainerComponent = ({ loading, markers, displayCanvas, setCode, page }: MapContainerComponentProp) => {
    const [geojsonFile, setGeoFile] = useState<GeoJsonObject | null>(null)
    useEffect(() => {
        setGeoFile(basinData as GeoJsonObject);
    }, [])

    return (
        <MapContainer center={[6.709327322997796, -1.559240192997633]} zoom={12}>
            <TileLayer
                attribution='Google Maps'
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
            {loading ? (
                <div id="preloader" style={{ opacity: "0.9" }}>
                    <div id="status" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <span className="h3" > Loading </span>
                        <div className="bouncing-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            ) : Object.keys(markers).map((key, ind) => {
                const iconUrl = require(`../../utils/MarkerIcon/${markers[key]['marker']}`);
                const customIcon = new Icon({
                    iconUrl,
                })
                return (<Marker key={ind} position={[markers[key]['latitude'], markers[key]['longitude']]} icon={customIcon} eventHandlers={{
                    click: () => {
                        if (markers[key]['marker'] === 'no_data.png' || markers[key]['marker'] === 'rainfall_no_data.png') {
                            const errDiv = document.createElement('div');
                            errDiv.innerText = `No data available`;
                            errDiv.id = 'flash-message';
                            errDiv.className = 'flash-message';
                            const content = document.getElementById("content")
                            content?.appendChild(errDiv)
                            return
                        }

                        setCode!(markers[key]['code'])
                        if (page === "meteorological") {
                            displayCanvas(page, markers[key]['name'], markers[key]['code'])
                        }

                        if (page === "hydrological") {
                            displayCanvas(page, markers[key]['name'] + ' - (Water Levels)', markers[key]['code'])
                        }

                        if (page === "rainfall forecast") {
                            displayCanvas(page, markers[key]['name'] + ' - a six-day rainfall forecast starting today', markers[key]['code'])
                        }
                    }
                }}>
                    <Popup>
                        {`${markers[key]['name']} - ${markers[key]['code']}`}
                    </Popup>
                </Marker>)
            })}
            {geojsonFile && <GeoJSON data={geojsonFile} />}
        </MapContainer>
    )
}