import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

type TimeseriesChartProps = {
    seriesData: ApexAxisChartSeries
}

export const TimeseriesChart = ({ seriesData }: TimeseriesChartProps) => {
    const series: ApexAxisChartSeries = seriesData

    const options: ApexOptions = {
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [3, 3],
            curve: 'straight',
            dashArray: [0, 5]
        },
        markers: {
            size: 1,
            strokeWidth: 1,
            hover: {
                size: 3
            }
        },
        yaxis: [
            {
                title: {
                    text: "Discharge [m3/s]"
                },
                decimalsInFloat: 2,
            },
            {
                opposite: true,
                title: {
                    text: "Water Level [m]"
                },
                decimalsInFloat: 2,
            },
        ],
        xaxis: {
            type: "datetime",
            labels: {
                datetimeFormatter: {
                    year: "yyyy",
                    month: "MMM 'yy",
                    day: "dd MMM",
                    hour: "HH:mm",
                    minute: "HH:mm:ss",
                }
            }
        }
    }

    return <Chart options={options} series={series} type={'line'} width="100%" height="100%" className="apex-chartsr" />
}