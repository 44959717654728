import axios from "axios"
import { useEffect, useState } from "react"
import { ORCMapContainer } from "../../Components/MapContainer/ORCMapContainer"
import { Navbar } from "../../Components/Navbar/Navbar"
import { RightOffcanvas } from "../../Components/Offcanvas/RightOffcanvas"
import { Sidebar } from "../../Components/Sidebar/Sidebar"


export const WaterDischarge = () => {
    const [endDate, setEndDate] = useState<Date | null>(new Date())
    const [loading, setLoading] = useState(false)
    const [sites, setSites] = useState<any>({})
    const [coordinates, setCoordinates] = useState<null | Array<{ id: 0, name: "", coordinates: [0, 0] }>>(null)

    const [site, setSite] = useState<any>({})

    useEffect(() => {
        setLoading(true)
        const end = endDate!.toISOString().split('.')[0] + 'Z'
        axios.get(`/backend-api/fews/orc-water-discharge?end=${end}`)
            .then(res => res.data)
            .then(data => {
                if (data.status === "success") {
                    setSites(data.sites_data)
                    setCoordinates(data.coordinates)
                    setLoading(false)
                }
                setLoading(false)
            })
            .catch((e) => { alert("Something went wrong, refresh the page again."); setLoading(false) })
    }, [endDate])

    return <>
        <Navbar />
        <Sidebar endDate={endDate} setEndDate={setEndDate} setLoading={setLoading} />
        {/* <Legend /> */}
        <div id="content" className="content-page">
            <div className="content" style={{ minHeight: 0 }}>
                <div className="container-fluid">
                    <div className="row">
                        <ORCMapContainer markers={coordinates} setSite={setSite} sites={sites} loading={loading} />
                        <RightOffcanvas scatterplots={site["scatter_plots"]} timeseries={site["timeseries_data"]} siteImage={site["video_img"]} />
                    </div>
                </div>
            </div>
        </div>
    </>
}
