import { ScatterPlot } from "../Graphs/ScatterPlot"
import { TimeseriesChart } from "../Graphs/Timeseries"


interface RightOffcanvasInterface {
    scatterplots: any[],
    timeseries: any[],
    siteImage?: any
}

export const RightOffcanvas = ({ siteImage, scatterplots = [{ name: "", data: [] }], timeseries = [{ name: "", data: [] }, { name: "", data: [] }] }: RightOffcanvasInterface) => {
    return (
        <>
            <div className="modal fade" id="imageModal" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myCenterModalLabel">{scatterplots[0].name} Water Discharge Image</h4>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div className="modal-body">
                            {siteImage ? <div><img width={"100%"} src={`data:image/jpg;base64,${siteImage}`} alt="site_image" /></div> : <p>No image to display</p>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">7-day data recordings of water discharge at {scatterplots[0].name}</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <button type="button" className="btn btn-secondary mb-2 w-25" data-bs-toggle="modal" data-bs-target="#imageModal">View Image</button>

                <div className="offcanvas-body p-0">
                    <div style={{ height: "40vh" }}>
                        <ScatterPlot seriesData={scatterplots} />
                    </div>
                    <hr />
                    <div style={{ height: "40vh" }}>
                        <TimeseriesChart seriesData={timeseries} />
                    </div>
                </div>
            </div>
        </>
    )
}