import axios from "axios"
import { useEffect, useState } from "react"
import { Legend } from "../../Components/Legend/Legend"
import { MapContainerComponent } from "../../Components/MapContainer/MapContainer"
import { Navbar } from "../../Components/Navbar/Navbar"
import { OffCanvas } from "../../Components/Offcanvas/Offcanvas"
import { Sidebar } from "../../Components/Sidebar/Sidebar"
import "../global.css"
export const RainfallForecast = () => {
    const [endDate, setEndDate] = useState<Date | null>(new Date())
    const [loading, setLoading] = useState(false)
    const [stationData, setStationData] = useState<any>({})
    const [markers, setMarkers] = useState<any>({})
    const [code, setCode] = useState("")
    const [title, setTitle] = useState('')
    const [series, setSeries] = useState<any[]>([])

    useEffect(() => {
        setInterval(() => {
            window.location.reload()
        }, 1000 * 60 * 60 * 1);
    }, [])

    useEffect(() => {
        axios.get(`/backend-api/fews/rainfall-forecast-data`)
            .then(res => res.data)
            .then(data => {
                console.log(data)
                if (data.status === "success") {
                    setStationData(data.data)
                    setMarkers(data.markers)
                    setLoading(false)
                }
                else {
                    alert(data.message)
                    setLoading(false)
                    return
                }
            })
            .catch((error) => { alert('Oops, something went wrong, try again later.'); setLoading(false) })
    }, [])

    const displayCanvas = (page: string, title: string, code: string) => {
        setTitle(title)
        setSeries(stationData[code])
        const btn = document.getElementById('trigger') as HTMLButtonElement;
        const offcanvas = document.getElementById('offcanvasBottom');
        if (!(offcanvas?.classList.contains('show'))) {
            btn.click()
        }
    }

    return (
        <>
            <Navbar />
            <Sidebar endDate={endDate} setEndDate={setEndDate} setLoading={setLoading} disable={true} />
            <Legend />
            <div id="content" className="content-page">
                <div className="content" style={{ minHeight: 0 }}>
                    <div className="container-fluid">
                        <div className="row">
                            <MapContainerComponent loading={loading} markers={markers} displayCanvas={displayCanvas} setCode={setCode} page="rainfall forecast" />
                            <OffCanvas title={title} page='rainfall forecast' series={series} seriesData={stationData} setSeries={setSeries} code={code} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}